<template>
  <div class="BOX">
    <div class="wh"></div>
    <div class="one-box3">
      <div class="one-box2-box">
        <img
          src="../assets/img/back.png"
          alt=""
          class="pic-back"
          @click="back"
        />
      </div>
      <div class="one-box2-box2">{{language10.c10}}</div>
      <div>&nbsp;</div>
    </div>
    <div class="wh"></div>
    <div class="one-box">
      <div class="one-box1">
        <div class="center">&nbsp;&nbsp;&nbsp;{{language10.a10}}</div>
        <div class="center">{{ username }}&nbsp;&nbsp;&nbsp;</div>
      </div>
      <div class="xhx"></div>

      <div class="one-box1" @click="into">
        <div class="center" style=" cursor: pointer;">&nbsp;&nbsp;&nbsp;{{language10.b10}}</div>
        <div class="center">
          <img src="../assets/img/backr.png" alt="" class="back2-pick" />
          &nbsp;&nbsp;&nbsp;
        </div>
      </div>
      <div class="xhx"></div>

      <!-- <div class="one-box1"><div class="one-box2">&nbsp;&nbsp;&nbsp;&nbsp;777</div><div class="box-2-2">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;777</div></div>
        <div class="one-box1"><div class="one-box2">&nbsp;&nbsp;&nbsp;&nbsp;888</div><div class="box-2-2">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;审核中</div></div> -->
    </div>
    <Menu></Menu>
  </div>
</template>

<script>
import axios from "axios";
import Menu from "../components/Menu.vue"
export default {
  components:{
    Menu,
  },
  data() {
    return {
      username: "",
      items: [
        {
          // name: "555,",
          // name1: "666",
          // name2: "777",
          // name3: "888",
          // name4: "999",
          // name5: "000",
        },
      ],

      // 翻译数组
      language10: {
        a10: "用户名",
        b10: "收件人账号",
        c10:"个人资料"
      },
      languageChinese10: {
        a10: "用户名",
        b10: "收件人账号",
         c10:"个人资料"
      },
      languageEnglish10: {
        a10: "User Name",
        b10: "Recipient's account number",
        c10: "Personal Information"
        
      },
      //繁体中文数组
      languageJT10: {
        a10: "用戶名",
        b10: "收件人帳號",
        c10:"個人資料",
      },
      //泰语数组
      languageTY10: {
        a10: "ชื่อผู้ใช้",
        b10: "บัญชีผู้รับ",
        c10: "ข้อมูลส่วนตัว"
      },

      //韩语数组
      languageHY10: {
        a10: "사용자 이름",
        b10: "받는 사람의 계좌 번호",
      },

      //日语数组
      languageRY10: {
        a10: "ユーザー名",
        b10: "受取人の口座番号",
        c10:"個人情報",
      },
      languageYDY10: {
        a10: "यूजर नेम",
        b10: "प्राप्तकर्ता खाता",
        c10:"프로필",
      },

      //印度语数组
    };
  },
  mounted() {
    this.username=sessionStorage.getItem("userName")
    // this.getBankCard();
     if (localStorage.getItem("language") == "chinese") {
      this.language10 = this.languageChinese10;
    }
    if (localStorage.getItem("language") == "english") {
      this.language10 = this.languageEnglish10;
    }
    if (localStorage.getItem("language") == "JT") {
      this.language10 = this.languageJT10;
    }
    if (localStorage.getItem("language") == "HY") {
      this.language10 = this.languageHY10;
    }
    if (localStorage.getItem("language") == "TY") {
      this.language10 = this.languageTY10;
    }
    if (localStorage.getItem("language") == "YDY") {
      this.language10 = this.languageYDY10;
    }
    if (localStorage.getItem("language") == "RY") {
      this.language10 = this.languageRY10;
    }
  },
  methods: {
    // getBankCard() {
    //   let token = sessionStorage.getItem("token");
    //   axios
    //     .get(`/user/queryacc.do?uid=${token}`)
    //     .then((res) => {
    //       ////console.log(res);
    //       try {
    //         if (res.data.code == 200) {
    //           this.username = res.data.data;
    //           ////console.log(this.bankcard, this.bankname);
    //         }
    //       } catch (error) {}
    //     })
    //     .catch(() => {});
    // },
    back() {
      this.$router.go(-1);
    },
    into() {
      this.$router.push("/RecipientAccount");
    },
  },
};
</script>

<style scoped>
.BOX {
  display: flex;
  align-items: center;
  flex-direction: column;

  height: 100vh;
  margin-top: 0px;
  background-color: rgb(217, 222, 222);
}
.one-box {
  display: flex;
  flex-direction: column;
  align-content: center;
  width: 100%;
 

  background-color: rgb(217, 222, 222);
}
.one-box1 {
  display: flex;
  justify-content: space-between;
  height: 30px;
  color: rgb(144, 148, 148);
  margin-top: 10px;
  margin-left: 10px;
  margin-bottom: 10px;
}
.one-box2 {
  display: flex;
  width: 100%;
}
.one-box3 {
  display: flex;
  justify-content:space-around;
  width: 100%;
  background-color: rgb(255, 255, 255);
}

.one-box2-box {
 
  display: flex;
  align-items: center;
  
}

.pic-back {
  width: 10px;
  height: auto;
  
  
}
.box-2-2 {
  width: 100%;

}
.xhx {
  width: 100%;
  height: 1px;
  background-color: rgb(208, 203, 203);
}
.one-box2-box2 {
  display: flex;
  justify-content: center;
  width: 50%;
}
.back2-pick {
  width: 20px;
  height: 20px;
}
.center {
  display: flex;
  align-items: center;
}
.wh {
  width: 100%;
  background: #ffffff;
  height: 10px;
}
</style>