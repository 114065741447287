<template>
  <div class="BOX">
    <div class="one-box3">
      <div class="one-box2-box">
       <img
          src="../assets/img/back.png"
          alt=""
          class="pic-back"
          @click="back"
        />
      </div>
      <div class="one-box2-box2">{{ language.a }}</div>
      <div>&nbsp;</div>
    </div>
    <div class="one-box" v-if="determine">
      <div class="one-box1">
        <div class="center">&nbsp;&nbsp;&nbsp;{{ bankname }}</div>
        <div class="center">&nbsp;&nbsp;{{ banknumber }}&nbsp;&nbsp;&nbsp;</div>
        <div class="center">{{userid}}</div>
      </div>
      <div class="xhx"></div>

      <!-- <div class="one-box1"><div class="one-box2">&nbsp;&nbsp;&nbsp;&nbsp;777</div><div class="box-2-2">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;777</div></div>
        <div class="one-box1"><div class="one-box2">&nbsp;&nbsp;&nbsp;&nbsp;888</div><div class="box-2-2">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;审核中</div></div> -->
    </div>
    <div class="kefu-box" v-if="qhbt1">
      <button class="kefu-bt" @click="kefubt">{{language.b}}</button>
    </div>
    <div class="kefu-box" v-if="qhbt2">
      <button class="kefu-bt" @click="kefubt">{{language.c}}</button>
    </div>
    <Menu></Menu>
  </div>
</template>

<script>
import axios from "axios";
import Menu from "../components/Menu.vue"
export default {
  components:{
    Menu
  },
  mounted() {
    if (localStorage.getItem("language") == "chinese") {
      this.language = this.languageChinese;
    }
    if (localStorage.getItem("language") == "english") {
      this.language = this.languageEnglish;
    }
    if (localStorage.getItem("language") == "JT") {
      this.language = this.languageJT;
    }
    if (localStorage.getItem("language") == "HY") {
      this.language = this.languageHY;
    }
    if (localStorage.getItem("language") == "TY") {
      this.language = this.languageTY;
    }
    if (localStorage.getItem("language") == "YDY") {
      this.language = this.languageYDY;
    }
    if (localStorage.getItem("language") == "RY") {
      this.language = this.languageRY;
    }
    this.shuaxin();
  },
  methods: {
    shuaxin() {
      let token = sessionStorage.getItem("token");
      // ////console.log(token);
      axios
        .get(`/userbank/single.do?uid=${+token}`)
        .then((res) => {
          // ////console.log(res.data);
          if (res.data.code == 200) {
             ////console.log(res);
            this.bankname = res.data.data.bankname;
            this.zhuanhuan(res.data.data.bankcode);
            this.userid = res.data.data.name;
            this.qhbt1 = 1;
           
          } else if (res.data.code == 500) {
            (this.determine = false), (this.qhbt2 = 1);
            // ////console.log(this.qhbt);
          }
        })
        .catch();
    },
    zhuanhuan(jc) {
      if(jc.length==10){
      let star =jc.substring(0,3);
      let middleStars ="****";
      let end = jc.substring(7,11);
      this.banknumber = star + middleStars+end
      }
      if(jc.length==11){
      let star =jc.substring(0,3);
      let middleStars ="*****";
      let end = jc.substring(8,12);
      this.banknumber = star + middleStars+end
      }
      if(jc.length==12){
      let star =jc.substring(0,3);
      let middleStars ="*****";
      let end = jc.substring(9,13);
      this.banknumber = star + middleStars+end
      }
    },
    kefubt() {
      if (this.qhbt1 == 1) this.$router.push("/CustomerService");
      if (this.qhbt2 == 1) this.$router.push("/Bindbankcard");
    },

    back() {
      this.$router.go(-1);
    },
  },

  data() {
    return {
      determine: true,
      qiehuan: "",
      bankname: "",
      banknumber:"",
      username:"",
      userid:"",
      qhbt1: 0,
      qhbt2: 0,
      // 翻译数组
      language: {
        a: "银行卡管理",
        b: "修改银行卡请联系客服",
        c: "添加银行卡",
      },
      languageChinese: {
        a: "银行卡管理",
        b: "修改银行卡请联系客服",
        c: "添加银行卡",
      },
      languageEnglish: {
        a: "Bank Card Management",
        b: "Modify please contact customer service",
        c: "Add Bank Card",
      },
      //繁体中文数组
      languageJT: {
        a: "銀行卡管理",
        b: "修改銀行卡請聯繫客服",
        c: "添加銀行卡",
      },
      //泰语数组
      languageTY: {
        a: "การจัดการบัตรธนาคาร",
        b: "หากต้องการแก้ไขบัตรธนาคาร โปรดติดต่อฝ่ายบริการลูกค้าออนไลน์",
        c: "เพิ่มบัตรธนาคาร",
      },

      //韩语数组
      languageHY: {
        a: "은행 카드 관리",
        b: "수정 고객 서비스에 문의",
        c: "은행카드 추가",
      },

      //日语数组
      languageRY: {
        a: "銀行カード管理",
        b: "修正 カスタマーサービスに連絡",
        c: "銀行カードの追加",
      },
      languageYDY: {
        a: "बैंक कार्ड प्रबंधन",
        b: "ग्राहक सेवा परिवर्धित करें और संपर्क करें",
        c: "बैंक कार्ड जोड़ें",
      },
    };
  },
};
</script>

<style scoped>

.BOX {
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 100vh;
  margin-top: 0px;
  background-color: rgb(217, 222, 222);
}
.one-box {
  display: flex;
  flex-direction: column;
  align-content: center;
  width: 100%;
 
  background-color: rgb(217, 222, 222);
}
.one-box1 {
  display: flex;
  font-size: 14px;
  padding: 10px;
  color: rgb(144, 148, 148);
}
.one-box2 {
  display: flex;
  width: 100%;
}
.one-box3 {
  width: 100%;
    display: flex;
  justify-content: space-around;
  background-color: rgb(255, 255, 255);
  padding: 10px;
}
.one-box3-title{

width: 50%;

}


.one-box2-box2 {
    display: flex;
  justify-content: center;
  width: 50%;
}
.pic-back {
  width: 10px;
  height: auto;
}
.box-2-2 {
  width: 100%;
}
.xhx {
  width: 100%;
  height: 1px;
  background-color: rgb(208, 203, 203);
}
.back2-pick {
  width: 20px;
  height: 20px;
}
.center {
  display: flex;
  align-items: center;
}
.kefu-box {
  margin-top: 15px;
  display: flex;
  justify-content: center;
  width: 100%;
  min-height: 30px;

}
.kefu-box button{
  width: 98%;
  height: 35px;
  font-size: 14px;
  border-radius: 3px 3px 3px 3px;
  box-shadow: 0px 0px 3px  #c73131;
}
.kefu-bt {
  /* height: 45px; */
  width: 90%;
  background-color: #c73131;
  border: none;
  color: aliceblue;
  font-size: 20px;
}
</style>