<template>
  <div class="BOX">
    <div class="wh"></div>
    <div class="one-box3">
      <div class="one-box2-box">
        <img
          src="../assets/img/back.png"
          alt=""
          class="pic-back"
          @click="back"
        />
      </div>
      <div class="one-box2-box2">{{ language.a }}</div>
      <div>&nbsp;</div>
    </div>
      <div class="wh"></div>
    <div class="BOX2">
      <div class="jj">
        <span>{{ language.b }}</span>
        <input
          type="password"
          :placeholder="language.f"
          class="input"
          v-model.trim="data.oldpass"
        />
      </div>
      <div class="xhx"></div>
      <div class="jj">
        <span> {{ language.c }}</span>
        <input
          type="password"
          :placeholder="language.f"
          class="input"
          v-model.trim="data.newpass"
        />
      </div>
      <div class="xhx"></div>
      <div class="jj">
        <span>{{ language.d }}</span
        ><input
          type="password"
          :placeholder="language.f"
          class="input"
          v-model.trim="newpass1"
        />
      </div>
      <div class="xhx"></div>
    </div>
    <div class="kefu-box">
      <button class="kefu-bt" @click="ubmitto">{{ language.e }}</button>
    </div>
    <Menu></Menu>
  </div>
</template>

<script>
import axios from "axios";
import Menu from "../components/Menu.vue"
export default {
  components:{
    Menu
  },
  methods: {
    ubmitto() {
      if (
        this.data.newpass == this.newpass1 &&
        this.data.newpass != "" &&
        this.newpass1 != "" &&
        this.data.oldpass != ""
      ) {
        if (this.data.newpass != this.data.oldpass) {
          let token = sessionStorage.getItem("token");
          axios
            .post(`/user/updatepass.do`, {
              id: +token,
              newpass: this.data.newpass,
              oldpass: this.data.oldpass,
            })
            .then((res) => {
              if (res.data.code == 200) {
                sessionStorage.setItem("token", "");
                this.$router.push("/");
                this.$message.success(this.language.error3);
              }
              if (res.data.code == 500) {
                this.$message.error(this.language.error2);
              }
            })
            .catch();
        } else {
          this.$message.error(this.language.error);
        }
      } else {
        this.$message.error(this.language.error1);
      }
    },
    ModifyPayment() {
      this.$router.push("/ModifyPayment");
    },
    Modifylogin() {
      this.$router.push("/Modifylogin");
    },
    back() {
      this.$router.go(-1);
    },
    into() {
      this.$router.push("/RecipientAccount");
    },
  },

  data() {
    return {
      newpass1: "",
      data: {
        newpass: "",
        oldpass: "",
      },
      // 翻译数组
      language: {
        a: "修改登录密码",
        b: "原登录密码：",
        c: "新登录密码：",
        d: "请确认密码：",
        e: "提交",
        f: "请输入",
        error:"重置密码不能与旧密码一致",
        error1:"请检查旧密码是否错误/两次新密码是否相同!",
        error2:"登录密码错误",
        error3:"修改成功"
      },
      languageChinese: {
        a: "修改登录密码",
        b: "原登录密码：",
        c: "新登录密码：",
        d: "请确认密码：",
        e: "提交",
        f: "请输入",
        error:"重置密码不能与旧密码一致",
        error1:"请检查旧密码是否错误/两次新密码是否相同!",
        error2:"登录密码错误",
        error3:"修改成功"
      },
      languageEnglish: {
        a: "Change Login Password",
        b: "Original Login Password:",
        c: "New Login Password:",
        d: "Confirm Password:",
        e: "Submit",
        f:"Please Enter",
        error:"The new password cannot be the same as the old password when resetting.",
        error1:"The reset password cannot be the same as the old password",
        error2:"Incorrect payment password",
        error3:"Modification successful"
      },
      //繁体中文数组
      languageJT: {
        a: "修改登入密碼",
        b: "原登入密碼：",
        c: "新登入密碼：",
        d: "請確認密碼：",
        e: "提交",
        f: "請輸入",
        error:"重置密碼不能與舊密碼一致",
        error1:"請檢查舊密碼是否錯誤/兩次新密碼是否相同！",
        error2:"支付密碼錯誤",
        error3:"修改成功"
      },
      //泰语数组
      languageTY: {
        a: "แก้ไขรหัสผ่านเข้าสู่ระบบ",
        b: "รหัสผ่านเข้าสู่ระบบเดิม:",
        c: "รหัสผ่านเข้าสู่ระบบใหม่:",
        d: "โปรดยืนยันรหัสผ่าน:",
        e: "ส่ง",
        f: "กรุณาป้อน",
        error:"รีเซ็ตรหัสผ่านไม่สอดคล้องกับรหัสผ่านเก่า",
        error1:"โปรดตรวจสอบว่ารหัสผ่านเก่าไม่ถูกต้อง / รหัสผ่านใหม่สองครั้งเหมือนกัน!",
        error2:"รหัสผ่านการชำระเงินผิดพลาด",
        error3:"แก้ไขสำเร็จ"
      },

      //韩语数组
      languageHY: {
        a: "로그인 암호 수정",
        b: "원래 로그인 암호:",
        c: "새로운 로그인 암호:",
        d: "암호를 확인하세요:",
        e: "제출",
        f: "입력하세요",
        error:"재설정 암호는 이전 암호와 일치하지 않습니다.",
        error1:"이전 암호가 잘못되었는지 / 두 번 새 암호가 동일한지 확인하십시오.",
        error2:"결제 암호 오류",
        error3:"수정 성공"
      },

      //日语数组
      languageRY: {
        a: "ログインパスワードを変更",
        b: "原ログインパスワード：",
        c: "新しいログインパスワード：",
        d: "パスワードを確認してください：",
        e: "送信",
        f: "入力してください",
        error:"リセットパスワードは古いパスワードと一致しない",
        error1:"古いパスワードが間違っているかどうか/新しいパスワードが2回同じかどうかをチェックしてください！",
        error2:"支払いパスワードエラー",
        error3:"変更に成功しました"
      },
      languageYDY: {
        a: "लॉगिन पासवर्ड संशोधित करें",
        b: "मूल लॉगिन पासवर्ड:",
        c: "नया लॉगिन पासवर्ड:",
        d: "पासवर्ड की पुष्टि करें:",
        e: "सबमिट",
        f: "कृपया दर्ज करें",
        error:"रीसेट पासवर्ड पुराने पासवर्ड से समान नहीं ਹੋ ਸਕਦਾ",
        error1:"कृपया पुराना पासवर्ड गलत है/ दो बार नया पासवर्ड समान है या नहीं, जाँच करें!",
        error2:"भुगतान पासवर्ड गलत",
        error3:"संपादन सफल"
      },
    };
  },
  mounted() {
    if (localStorage.getItem("language") == "chinese") {
      this.language = this.languageChinese;
    }
    if (localStorage.getItem("language") == "english") {
      this.language = this.languageEnglish;
    }
    if (localStorage.getItem("language") == "JT") {
      this.language = this.languageJT;
    }
    if (localStorage.getItem("language") == "HY") {
      this.language = this.languageHY;
    }
    if (localStorage.getItem("language") == "TY") {
      this.language = this.languageTY;
    }
    if (localStorage.getItem("language") == "YDY") {
      this.language = this.languageYDY;
    }
    if (localStorage.getItem("language") == "RY") {
      this.language = this.languageRY;
    }
  },
};
</script>

<style scoped>
.BOX {
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 100vh;
  overflow-y: scroll;
  background-color: rgb(217, 222, 222);
}
.one-box {
  display: flex;
  flex-direction: column;
  align-content: center;
  width: 100%;
  margin-top: 15px;

  background-color: rgb(217, 222, 222);
}
.one-box1 {
  display: flex;
  justify-content: space-between;
  height: 30px;
  color: rgb(144, 148, 148);
}
.one-box2 {
  display: flex;
  width: 100%;
}
.one-box3 {
  display: flex;
  justify-content: space-around;;
  width: 100%;
  background-color: rgb(255, 255, 255);

}

.one-box2-box {

}


.pic-back {
  width: 10px;
  height: auto;

}
.BOX2 {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.xhx {
  width: 100%;
  height: 1px;
  background-color: rgb(193, 198, 203);
}
.jj {
  padding: 5px;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 10px;
  font-size: 17px;
  color: #808080;
  display: flex;
}
.jj span {
  width: 50%;
}

/* .jj input{

} */

.kefu-box {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 10px;
}
.kefu-bt {
  width: 60%;
  height: 45px;
  background-color: rgb(174, 27, 27);
  border: none;
  color: aliceblue;
  font-size: 20px;
  cursor: pointer;
}
.input {
  border: none;
  outline: none;
  background-color: transparent;
}
.wh{
  width: 100%;
  height: 10px;
  background-color: #ffffff;
}
.one-box2-box2 {
    display: flex;
  justify-content: center;
  width: 50%;
}
</style>