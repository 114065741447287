<template>
  <div class="b">
    <div class="menu_box">
      <div class="buts">
        <router-link to="/home">
          <button class="but but0">
            <img
              v-if="colorWhiteSwitch1"
              src="../assets/img/1.png"
              style="width: 30px; height: 30px"
            />
            <img
              v-if="colorBuleSwitch1"
              src="../assets/img/11.png"
              style="width: 30px; height: 30px"
            />
            <span>{{ language12.a12 }}</span>
          </button>
        </router-link>
        <router-link to="/wallet">
          <button class="but but1">
            <img
              v-if="colorWhiteSwitch2"
              src="../assets/img/2.png"
              style="width: 30px; height: 30px"
            />
            <img
              v-if="colorBuleSwitch2"
              src="../assets/img/22.png"
              style="width: 30px; height: 30px"
            />

            <span>{{ language12.b12 }}</span>
          </button>
        </router-link>
        <router-link to="/tradeOrderView">
          <button class="but but2">
            <img
              v-if="colorWhiteSwitch3"
              src="../assets/img/3.png"
              style="width: 30px; height: 30px"
            />
            <img
              v-if="colorBuleSwitch3"
              src="../assets/img/33.png"
              style="width: 30px; height: 30px"
            />
            <span>{{ language12.c12 }}</span>
          </button>
        </router-link>
        <router-link to="/fiscalmanagement">
          <button class="but but3">
            <img
              v-if="colorWhiteSwitch4"
              src="../assets/img/4.png"
              style="width: 30px; height: 30px"
            />
            <img
              v-if="colorBuleSwitch4"
              src="../assets/img/44.png"
              style="width: 30px; height: 30px"
            />
            <span>{{ language12.d12 }}</span>
          </button>
        </router-link>
        <router-link to="/my">
          <button class="but but4">
            <img
              v-if="colorWhiteSwitch5"
              src="../assets/img/5.png"
              style="width: 30px; height: 30px"
            />
            <img
              v-if="colorBuleSwitch5"
              src="../assets/img/55.png"
              style="width: 30px; height: 30px"
            />
            <span>{{ language12.e12 }}</span>
          </button>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      language: localStorage.getItem("language"),
      colorWhiteSwitch1: true,
      colorBuleSwitch1: false,

      colorWhiteSwitch2: true,
      colorBuleSwitch2: false,

      colorWhiteSwitch3: true,
      colorBuleSwitch3: false,

      colorWhiteSwitch4: true,
      colorBuleSwitch4: false,

      colorWhiteSwitch5: true,
      colorBuleSwitch5: false,

      // 翻译数组
      language12: {
        a12: "行情",
        b12: "资产",
        c12: "交易订单",
        d12: "理财",
        e12: "我的",
      },
      languageChinese12: {
        a12: "行情",
        b12: "资产",
        c12: "交易订单",
        d12: "理财",
        e12: "我的",
      },
      languageEnglish12: {
        a12: "Market",
        b12: "Assets",
        c12: "Trade Orders",
        d12: "Investment",
        e12: "My",
      },
      //繁体中文数组
      languageJT12: {
        a12: "行情",
        b12: "資產",
        c12: "交易訂單",
        d12: "理財",
        e12: "我的",
      },
      //泰语数组
      languageTY12: {
        a12: "ราคาตลาด",
        b12: "ทรัพย์สิน",
        c12: "คำสั่งซื้อขาย",
        d12: "ออมเงิน",
        e12: "ของฉัน",
      },

      //韩语数组
      languageHY12: {
        a12: "따옴표",
        b12: "자산",
        c12: "거래 주문",
        d12: "자금 관리",
        e12: "내",
      },

      //日语数组
      languageRY12: {
        a12: "市場動向",
        b12: "資産",
        c12: "取引注文",
        d12: "資産運用",
        e12: "マイ",
      },
      languageYDY12: {
        a12: "बाजार स्थिति",
        b12: "संपत्ति",
        c12: "ट्रेडिंग ऑर्डर",
        d12: "वित्तीय परिवर्तन",
        e12: "मेरा",
      },

      //印度语数组
    };
  },
  methods: {},
  mounted() {
    if (localStorage.getItem("language") == "chinese") {
      this.language12 = this.languageChinese12;
      localStorage.setItem("language", "chinese");
    }
    if (localStorage.getItem("language") == "english") {
      this.language12 = this.languageEnglish12;
      localStorage.setItem("language", "english");
    }
    if (localStorage.getItem("language") == "JT") {
      this.language12 = this.languageJT12;
    }
    if (localStorage.getItem("language") == "HY") {
      this.language12 = this.languageHY12;
    }
    if (localStorage.getItem("language") == "TY") {
      this.language12 = this.languageTY12;
    }
    if (localStorage.getItem("language") == "YDY") {
      this.language12 = this.languageYDY12;
    }
    if (localStorage.getItem("language") == "RY") {
      this.language12 = this.languageRY12;
    }
    if (this.$route.path == "/home") {
      this.colorBuleSwitch1 = true;
      this.colorWhiteSwitch1 = false;
    }
    if (this.$route.path == "/wallet") {
      this.colorBuleSwitch2 = true;
      this.colorWhiteSwitch2 = false;
    }
    if (this.$route.path == "/tradeOrderView") {
      this.colorBuleSwitch3 = true;
      this.colorWhiteSwitch3 = false;
    }
    if (this.$route.path == "/fiscalmanagement") {
      this.colorBuleSwitch4 = true;
      this.colorWhiteSwitch4 = false;
    }
    if (this.$route.path == "/my") {
      this.colorBuleSwitch5 = true;
      this.colorWhiteSwitch5 = false;
    } else if (this.$route.path.match("/HomeDetails")) {
      this.colorBuleSwitch1 = true;
      this.colorWhiteSwitch1 = false;
    } else if (
      this.$route.path != "/my" &&
      this.$route.path != "/home" &&
      this.$route.path != "/wallet" &&
      this.$route.path != "/tradeOrderView" &&
      this.$route.path != "/fiscalmanagement"
    ) {
      this.colorBuleSwitch5 = true;
      this.colorWhiteSwitch5 = false;
    }
  },
};
</script>

<style scoped>
a {
  text-decoration: none;
}
.router-link-active {
  text-decoration: none;
}

.menu-b {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.b {
  position: fixed;
  width: 100%;
  z-index: 9999;
  bottom: 0;
  background-color: #fff;
  left: 0;
}
.menu_box {
  display: flex;
  justify-content: center;
  background-color: #fff;
  width: 100%;
}
.buts {
  display: flex;
  justify-content: space-around;
  width: 100%;
  padding: 5px 0 5px 0;
}
.buts .but {
  height: 50px;
  border: none;
  font-size: 12px;
  background-color: rgba(0, 0, 0, 0.001);
  cursor: pointer;
  color: gray;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.but0::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 2px; /* 下划线高度 */
  background-color: #f3eaea;
  transition: width 0.3s ease-in-out; /* 过渡效果 */
}
.but0:hover::before {
  width: 100%;
}

.but1::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 2px; /* 下划线高度 */
  background-color: #f3eaea;
  transition: width 0.3s ease-in-out; /* 过渡效果 */
}
.but1:hover::before {
  width: 100%;
}
.but2::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 2px; /* 下划线高度 */
  background-color: #f3eaea;
  transition: width 0.3s ease-in-out; /* 过渡效果 */
}
.but2:hover::before {
  width: 100%;
}
.but3::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 2px; /* 下划线高度 */
  background-color: #f3eaea;
  transition: width 0.3s ease-in-out; /* 过渡效果 */
}
.but3:hover::before {
  width: 100%;
}
.but4::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 2px; /* 下划线高度 */
  background-color: #f3eaea;
  transition: width 0.3s ease-in-out; /* 过渡效果 */
}
.but4:hover::before {
  width: 100%;
}
.but5::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 2px; /* 下划线高度 */
  background-color: #f3eaea;
  transition: width 0.3s ease-in-out; /* 过渡效果 */
}
.but5:hover::before {
  width: 200%;
}
.searchs {
  width: 350px;
  display: flex;
  justify-content: space-around;
  align-content: center;
  align-items: center;
}
.search {
  display: flex;
  justify-content: center;
  border: 1px solid;
  border-radius: 20px;
  width: 250px;
  height: 30px;
  padding: 5px;
}

.search input {
  width: 160px;
  padding: 5px;
  outline: none;
  border: none;
}
.search button {
  width: 60px;
  padding: 3px;
  outline: none;
  margin-left: 3px;
  border: none;
  background-color: white;
  cursor: pointer;
}
.user {
  border: none;
  background-color: white;
  cursor: pointer;
}
</style>