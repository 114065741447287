<template>
  <div id="app">
    <!-- <Login/> -->
    <router-view></router-view>
</div>
</template>


<script>
export default {
  name: "app",
  components: {
  },
  mounted() {
       
  }
};
</script>
<style>
body{
  padding: 0px;
  margin: 0px;
}
/* #app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

*/
</style>
