<template>
  <div class="BOX">
    <div class="one-box3">
      <div class="one-box2-box">
        <img
          src="../assets/img/back.png"
          alt=""
          class="pic-back"
          @click="back"
        />
      </div>
      <div class="one-box2-box2">{{ language.a }}</div>
      <div>&nbsp;</div>
    </div>
    <div class="BOX2">
      <div class="jj">
        <span>{{ language.b }}</span>
        <input
          type="password"
          :placeholder="language.f"
          class="input"
          v-model.trim="data.oldpass"
        />
      </div>
      <div class="xhx"></div>
      <div class="jj">
        <span>{{ language.c }}</span
        ><input
          type="password"
          :placeholder="language.f"
          class="input"
          v-model.trim="data.newpass"
        />
      </div>
      <div class="xhx"></div>
      <div class="jj">
        <span>{{ language.d }}</span
        ><input
          type="password"
          :placeholder="language.f"
          class="input"
          v-model.trim="newpass1"
        />
      </div>
      <div class="xhx"></div>
    </div>
    <div class="kefu-box">
      <button class="kefu-bt" @click="ubmitto">{{ language.e }}</button>
    </div>
    <Menu></Menu>
  </div>
</template>

<script>
import axios from "axios";
import Menu from "../components/Menu.vue";
export default {
  components: { Menu },
  methods: {
    ubmitto() {
      if (
        this.data.newpass != "" &&
        this.newpass1 != "" &&
        this.data.oldpass != ""
      ) {
        if (this.data.newpass == this.newpass1) {
          if (this.data.newpass != this.data.oldpass) {
            let token = sessionStorage.getItem("token");
            axios
              .post(`/user/updatepaypass.do`, {
                id: +token,
                newpass: this.data.newpass,
                oldpass: this.data.oldpass,
              })
              .then((res) => {
                if (res.data.code == 200) {
                  this.$router.push("/My");
                  this.$message.success(this.language.information1);
                } else if (res.data.code == 500) {
                  this.$message.error(this.language.information2);
                }
              })
              .catch();
          } else {
            this.$message.error(this.language.information3);
          }
        } else {
          this.$message.error(this.language.information4);
        }
      } else {
        this.$message.error(this.language.information5);
      }
    },
    ModifyPayment() {
      this.$router.push("/ModifyPayment");
    },
    Modifylogin() {
      this.$router.push("/Modifylogin");
    },
    back() {
      this.$router.go(-1);
    },
    into() {
      this.$router.push("/RecipientAccount");
    },
  },

  data() {
    return {
      newpass1: "",
      // 翻译数组
      language: {
        a: "修改支付密码",
        b: "请输入旧密码:",
        c: "请输入新密码:",
        d: "确认新密码:",
        e: "提交",
        f: "请输入",
        information1: "修改成功",
        information2: "请检查原支付密码输入是否正确!",
        information3: "重置密码不能与旧密码一致!",
        information4: "请检查旧密码是否错误/两次新密码是否相同!",
        information5: "请将信息填写完整!",
      },
      languageChinese: {
        a: "修改支付密码",
        b: "请输入旧密码:",
        c: "请输入新密码:",
        d: "确认新密码:",
        e: "提交",
        f: "请输入",
        information1: "修改成功",
        information2: "请检查原支付密码输入是否正确!",
        information3: "重置密码不能与旧密码一致!",
        information4: "请检查旧密码是否错误/两次新密码是否相同!",
        information5: "请将信息填写完整!",
      },
      languageEnglish: {
        a: "Change Payment Password",
        b: "Please enter the old password:",
        c: "Please enter the new password:",
        d: "Confirm the new password:",
        e: "Submit",
        f: "Please enter",
        information1: "Modification successful",
        information2:
          "Please check if the original payment password entered is correct!",
        information3:
          "The reset password cannot be the same as the old password!",
        information4:
          "Please check if the old password is incorrect/if the two new passwords are the same!",
        information5: "Please fill in the information completely!",
      },
      //繁体中文数组
      languageJT: {
        a: "修改支付密碼",
        b: "請輸入舊密碼:",
        c: "請輸入新密碼:",
        d: "確認新密碼:",
        e: "提交",
        f: "請輸入",
        information1: "修改成功",
        information2: "請檢查原支付密碼輸入是否正確!",
        information3: "重置密碼不能與舊密碼一致!",
        information4: "請檢查舊密碼是否錯誤/兩次新密碼是否相同!",
        information5: "請將信息填寫完整!",
      },
      //泰语数组
      languageTY: {
        a: "แก้ไขรหัสผ่านชำระเงิน",
        b: "กรุณาป้อนรหัสผ่านเก่า:",
        c: "กรุณาป้อนรหัสผ่านใหม่:",
        d: "ยืนยันรหัสผ่านใหม่:",
        e: "ส่ง",
        f: "กรุณาป้อน",
        information1: "ความสำเร็จในการแก้ไข",
        information2:
          "กรุณาตรวจสอบว่าการป้อนรหัสผ่านการชำระเงินเดิมถูกต้องหรือไม่",
        information3: "รีเซ็ตรหัสผ่านไม่สอดคล้องกับรหัสผ่านเก่า !",
        information4:
          "โปรดตรวจสอบว่ารหัสผ่านเก่าไม่ถูกต้อง / รหัสผ่านใหม่สองครั้งเหมือนกัน!",
        information5: "กรุณากรอกข้อมูลให้ครบถ้วน!",
      },

      //韩语数组
      languageHY: {
        a: "결제 비밀번호 변경",
        b: "원래 비밀번호를 입력하세요:",
        c: "새로운 비밀번호를 입력하세요:",
        d: "새로운 비밀번호를 확인하세요:",
        e: "제출",
        f: "입력하세요",
        information1: "수정 성공",
        information2: "원래 지불 비밀번호를 잘못 입력했는지 확인해주세요!",
        information3: "비밀번호 재설정은 이전 비밀번호와 동일일 수 없습니다!",
        information4:
          "이전 비밀번호를 잘못 입력했는지/새로운 비밀번호 두 번에 입력한 내용이 동일한지 확인해주세요!",
        information5: "정보를 완전히 작성해주세요!",
      },

      //日语数组
      languageRY: {
        a: "支払いパスワードを変更",
        b: "旧パスワードを入力してください:",
        c: "新パスワードを入力してください:",
        d: "新パスワードを確認してください:",
        e: "送信",
        f: "入力してください",
        information1: "修正成功",
        information2: "原支払いパスワードの入力を確認してください！",
        information3: "リセットパスワードは旧パスワードと同じに設定できません!",
        information4:
          "旧パスワードが誤入力か/新パスワードの二次入力が一致か確認してください！",
        information5: "情報を完全に記入してください！",
      },
      languageYDY: {
        a: "भुगतान पासवर्ड संशोधित करें",
        b: "कृपया पुराने पासवर्ड दर्ज करें:",
        c: "कृपया नए पासवर्ड दर्ज करें:",
        d: "नए पासवर्ड की पुष्टि करें:",
        e: "सबमिट करें",
        f: "कृपया दर्ज करें",
        information1: "संपादन सफल",
        information2: "कृपया जाँच करें कि मूल भुगतान पासवर्ड का इनपुट सही है!",
        information3: "रीसेट पासवर्ड पुराने पासवर्ड के समान नहीं ਹੋ सकता!",
        information4:
          "कृपया जाँच करें कि पुराने पासवर्ड में गलती है/दो बार नए पासवर्ड समान हैं!",
        information5: "कृपया जानकारी को पूर्ण रूप से भरें!",
      },
      data: {
        id: +sessionStorage.getItem("token"),
        newpass: "",
        oldpass: "",
      },
    };
  },
  mounted() {
    localStorage.getItem("language")
      ? ""
      : localStorage.setItem("language", "TY");
    if (localStorage.getItem("language") == "chinese") {
      this.language = this.languageChinese;
    }
    if (localStorage.getItem("language") == "english") {
      this.language = this.languageEnglish;
    }
    if (localStorage.getItem("language") == "JT") {
      this.language = this.languageJT;
    }
    if (localStorage.getItem("language") == "HY") {
      this.language = this.languageHY;
    }
    if (localStorage.getItem("language") == "TY") {
      this.language = this.languageTY;
    }
    if (localStorage.getItem("language") == "YDY") {
      this.language = this.languageYDY;
    }
    if (localStorage.getItem("language") == "RY") {
      this.language = this.languageRY;
    }
  },
};
</script>

<style scoped>
.BOX {
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 100vh;
  background-color: rgb(217, 222, 222);
}

.one-box {
  display: flex;
  flex-direction: column;
  align-content: center;
  width: 100%;
  margin-top: 15px;

  background-color: rgb(217, 222, 222);
}

.one-box1 {
  display: flex;
  justify-content: space-between;
  height: 30px;
  color: rgb(144, 148, 148);
}

.one-box2 {
  display: flex;
  width: 100%;
}

.one-box3 {
  display: flex;
  width: 100%;
  justify-content: space-around;
  background-color: rgb(255, 255, 255);
  padding: 10px;
}

.one-box2-box {
  
}

.one-box2-box img {
  cursor: pointer;
}

.pic-back {
  width: 10px;
  height: auto;
  
}
.one-box2-box2 {
    display: flex;
  justify-content: center;
  width: 50%;
}

.BOX2 {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.xhx {
  width: 100%;
  height: 1px;
  background-color: rgb(193, 198, 203);
}

.jj {
  padding: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 10px;
  font-size: 17px;
  color: #808080;
  display: flex;
}

.jj span {
  width: 50%;
}

.kefu-box {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.kefu-bt {
  width: 60%;
  height: 45px;
  background-color: rgb(174, 27, 27);
  border: none;
  color: aliceblue;
  font-size: 20px;
  cursor: pointer;
}

.input {
  border: none;
  outline: none;
  background-color: transparent;
}
</style>