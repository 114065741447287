<template>
  <div class="BOX">
    <div class="one-box3">
      <div>
        <img
          src="../assets/img/lblack.png"
          alt=""
          class="pic-back"
          @click="back"
        />
      </div>
      <div class="one-box2-box">
        {{ language.b }}
      </div>
      <div></div>
    </div>
    <div>
      <quill-editor
        class="custom-quill-editor"
        ref="myQuillEditor"
        :options = "editorOption"
        v-model="context"
      />
    </div>
    <Menu></Menu>
  </div>
</template>

<script>
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import { quillEditor } from "vue-quill-editor";
import Menu from "../components/Menu.vue";
import axios from "axios";
export default {
  components: {
    Menu,
    quillEditor,
  },
  methods: {
    Modifylogin() {
      this.$router.push("/Modifylogin");
    },
    back() {
      this.$router.go(-1);
    },
    into() {
      this.$router.push("/RecipientAccount");
    },
    //获得公司公告接口信息
    goInf() {
      axios
        .get("/clientimg/gsinfoall.do")
        .then((res) => {
          if ((res.data.code = 200)) {
            this.context = res.data.data;
          } else {
            this.context = "No Data";
          }
        })
        .catch();
    },
  },

  data() {
    return {
      context:"",
      editorOption:{
        placeholder:"",
      },
      // 翻译数组
      language: {
        b: "公司简介",
      },
      languageChinese: {
        b: "公司简介",
      },
      languageEnglish: {
        b: "Company Introduction",
      },
      //繁体中文数组
      languageJT: {
        b: "公司簡介",
      },
      //泰语数组
      languageTY: {
        b: "ข้อมูล บริษัท ",
      },

      //韩语数组
      languageHY: {
        b: "회사 프로필",
      },

      //日语数组
      languageRY: {
        b: "会社概要",
      },
      languageYDY: {
        b: "कंपनी परिचय",
      },

      //印度语数组
    };
  },
  mounted() {
    if (localStorage.getItem("language") == "chinese") {
      this.language = this.languageChinese;
    }
    if (localStorage.getItem("language") == "english") {
      this.language = this.languageEnglish;
    }
    if (localStorage.getItem("language") == "JT") {
      this.language = this.languageJT;
    }
    if (localStorage.getItem("language") == "HY") {
      this.language = this.languageHY;
    }
    if (localStorage.getItem("language") == "TY") {
      this.language = this.languageTY;
    }
    if (localStorage.getItem("language") == "YDY") {
      this.language = this.languageYDY;
    }
    if (localStorage.getItem("language") == "RY") {
      this.language = this.languageRY;
    }
    this.goInf();

     this.$refs.myQuillEditor.quill.disable();
  },
};
</script>

<style scoped>
img {
  padding-left: 10px;
}
body {
  padding: 0px;
  margin: 0px;
}

.MessageBox {
  width: 100%;
  height: 10%;
  /* border: 1px solid red; */
  display: flex;
  justify-content: center;
  /* flex-direction: column; */
}

.MessageBox p {
  width: 100%;
  height: 100%;
  /* border:1px solid rebeccapurple; */
  /* margin-left: 10px; */
  color: rgb(159, 152, 152);
  font-size: 14px;
}

.BOX {
  margin: 0px;
  padding: 0px;
  width: 100%;
  height: auto;
  /* margin-top: 0px; */
  /* border-radius:10px 10px 10px 10px; */
  display: flex;
  align-items: center;
  flex-direction: column;
  /* background-color: rgb(223, 224, 224); */
  padding-bottom: 100px;
}

.one-box {
  display: flex;
  flex-direction: column;
  align-content: center;
  width: 100%;
  margin-top: 15px;

  background-color: rgb(217, 222, 222);
}

.one-box1 {
  display: flex;
  justify-content: space-between;
  height: 30px;
  color: rgb(144, 148, 148);
}

.one-box2 {
  display: flex;
  width: 100%;
}

.one-box3 {
  display: flex;
  /* space-around:space-evenly; */
  justify-content: space-between;
  width: 100%;
  background-color: rgb(255, 255, 255);
  padding: 10px 0;
}

.one-box2-box {
  width: 25%;
  height: 100%;
  /* border:1px solid red; */

  text-align: center;
  /* margin-right:35%; */
}

.one-box2-box p {
  width: 100%;
  height: 100%;
  /* border:1px solid red; */
  margin: 0px 0px 0px 0px;
}
.one-box2-box button {
  width: 100%;
  height: 100%;
  border: none;
  text-align: left;
  background-color: white;
}

.pic-back {
  width: 10px;
  height: auto;
  align-items: center;
}

.box-2-2 {
  width: 100%;
}

.xhx {
  width: 100%;
  height: 1px;
  background-color: rgb(208, 203, 203);
}

.back2-pick {
  width: 20px;
  height: 20px;
}

.center {
  display: flex;
  align-items: center;
  font-size: 8px;
}

/* 去掉边框 */
::v-deep.custom-quill-editor .ql-toolbar,
::v-deep.custom-quill-editor .ql-container {
  border: none;
  font-size: 12px;
}

/* 隐藏工具栏 */
::v-deep.custom-quill-editor .ql-toolbar {
  display: none;
}
</style>