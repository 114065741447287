<template>
  <div class="BOX">
    <div class="backjt" @click="PromotionandPosters">
      <img src="../assets/img/bsfh.png" alt="" class="jt" />
    </div>
    <div class="ewmmg">
      <div class="shareWx">
        <div id="qrcode" ref="qrcode"></div>
      </div>
      <p>{{language15.a15}}</p>
    </div>
    <div class="yqm">
      <div class="yqm-1">{{ language15.b15 }}{{ username }}</div>
    </div>
    <div class="jiangli">
      <div class="yqzh">
        <div class="jiangli-1">{{ language15.c15 }}</div>
        <div class="jiangli-2" @click="Promotionrecords">
          <div class="yqcolor">{{ language15.d15 }}</div>
          <div class="backr">
            <img src="../assets/img/backr.png" alt="" class="imgwh" />
          </div>
        </div>
      </div>
    </div>
    <div class="guizhe">
      <div class="mgleft">{{ language15.e15 }}</div>
    </div>
    <div class="hdgz">
      <div>{{ language15.f15 }}</div>
      <div>{{ language15.g15 }}</div>
      <div>{{ language15.h15 }}</div>
      <div>{{ language15.j15 }}</div>
    </div>
    <Menu></Menu>
  </div>
</template>

<script>
import axios from "axios";
import QRCode from "qrcodejs2";
import Menu from "../components/Menu.vue"
export default {
  components:{
    Menu
  },
  data() {
    return {
      username: "",

      // 翻译数组
      language15: {
        a15: "扫码二维码可直接注册",
        b15: "您的邀请码:",
        c15: "我的邀请奖励",
        d15: "查看我的邀请",
        e15: "邀请规则",
        f15: "1.本活动永久有效，欢迎参与",
        g15: "2.推荐名额可累计计算",
        h15: "3.满足条件后，请联系在线客服申请奖励",
      },
      languageChinese15: {
        a15: "扫码二维码可直接注册",
        b15: "您的邀请码:",
        c15: "我的邀请奖励",
        d15: "查看我的邀请",
        e15: "邀请规则",
        f15: "1.本活动永久有效，欢迎参与",
        g15: "2.推荐名额可累计计算",
        h15: "3.满足条件后，请联系在线客服申请奖励",
      },
      languageEnglish15: {
        a15: "Scan the QR code to register directly",
        b15: "Your invitation code:",
        c15: "My invitation reward",
        d15: "View my invitations",
        e15: "Invitation rules",
        f15: "1.This event is permanently valid, welcome to participate",
        g15: "2.The recommended quota can be calculated cumulatively",
        h15: "3.After meeting the conditions, please contact online customer service to apply for rewards",
        
      },
      //繁体中文数组
      languageJT15: {
        a15: "掃碼二維碼可直接註冊",
        b15: "您的邀請碼:",
        c15: "我的邀請獎勵",
        d15: "查看我的邀請",
        e15: "邀請規則",
        f15: "1.本活動永久有效，歡迎參與",
        g15: "2.推薦名額可累計計算",
        h15: "3.滿足條件後，請聯繫在線客服申請獎勵",
      },
      //泰语数组
      languageTY15: {
        a15: "สแกน QR เพื่อลงทะเบียนโดยตรง",
        b15: "รหัสเชิญของคุณ:",
        c15: "รางวัลเชิญของฉัน",
        d15: "ดูเชิญของฉัน",
        e15: "กົດລະเบียบการเชิญ",
        f15: "1.กิจกรรมนี้ใช้ได้ถาวร ยินดีต้อนรับเข้าร่วม",
        g15: "2.โควต้านี้แนะนำสามารถคำนวณสะสมได้",
        h15: "3.หลังจากปฎิบัติตามเงี่อนไขแล้ว โปรดติดฝ้าย",
        j15:"บริการลูกค้าออนไลน์เพื่อสมัครรับรางวัล",
      },

      //韩语数组
      languageHY15: {
        a15: "QR 코드를 스캔하면 바로 등록할 수 있습니다",
        b15: "귀하의 초대 코드:",
        c15: "내 초대 보상",
        d15: "내 초대 내역을 확인",
        e15: "초대 규칙",
        f15: "1.이 캠페인은 영구적으로 효력을 갖추고 있으며, 참여를 환영합니다",
        g15: "2.추천 명칭은 累積로 계산이 가능합니다",
        h15: "3.요건을 만족하시면, 온라인 고객 서비스에 연락하여 보상을 신청하세요",
      },

      //日语数组
      languageRY15: {
        a15: "QRコードをスキャンして直接登録できます",
        b15: "お客様の招待コード:",
        c15: "私の招待報酬",
        d15: "私の招待を確認",
        e15: "招待ルール",
        f15: "1.本キャンペーンは永久有効です。ご参加をお待ちしております",
        g15: "2.推薦人数は累計計算できます",
        h15: "3.条件を満たした後、オンラインカスタマーサービスに連絡を取って報酬を申請してください",
      },
      languageYDY15: {
        a15: "QR कोड को स्कैन करके सीधे पंजीकरण कर सकते हैं",
        b15: "आपका आमंत्रण कोड:",
        c15: "मेरा आमंत्रण इनाम",
        d15: "मेरे आमंत्रण देखें",
        e15: "आमंत्रण नियम",
        f15: "1. यह गतिविधि स्थायी रूप से प्रभावी है, भाग लेने के लिए स्वागत है",
        g15: "2. सुझाए गए नामों की गणना संचयी रूप से की जा सकती है",
        h15: "3. शर्તોं को पूरा करने के बाद, कृपया ऑनलाइन ग्राहक सेवा से संपर्क करें और इनाम के लिए आवेदन करें",
      },

      //印度语数组
    };
  },
  methods: {
    Promotionrecords() {
      this.$router.push("/Promotionrecords");
    },
    PromotionandPosters() {
      this.$router.push("/my");
    },
    Refresh() {
      let token = sessionStorage.getItem("token");
      axios
        .get(`/user/querybyinvitation.do?id=${token}`)
        .then((res) => {
          if (res.data.code == 200) {
            this.username = res.data.data;
            this.qrcode("https://www.axnmu.com/registe",this.username);
            ////console.log(res);
          }
        })
        .catch();
    },
    qrcode(url,id) {
      let qrcode = new QRCode("qrcode", {
        width: 191, // 二维码宽度，单位像素
        height: 191, // 二维码高度，单位像素
        text: url+'?id='+id, // 生成二维码的链接
      });
    },
  },
  mounted() {
    if (localStorage.getItem("language") == "chinese") {
      this.language15 = this.languageChinese15;
    }
    if (localStorage.getItem("language") == "english") {
      this.language15 = this.languageEnglish15;
    }
    if (localStorage.getItem("language") == "JT") {
      this.language15 = this.languageJT15;
    }
    if (localStorage.getItem("language") == "HY") {
      this.language15 = this.languageHY15;
    }
    if (localStorage.getItem("language") == "TY") {
      this.language15 = this.languageTY15;
    }
    if (localStorage.getItem("language") == "YDY") {
      this.language15 = this.languageYDY15;
    }
    if (localStorage.getItem("language") == "RY") {
      this.language15 = this.languageRY15;
    }
    this.Refresh();
  },
};
</script>

<style scoped>
.BOX {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: linear-gradient(to right, #3476fe, #34c7fe);
  min-height: 100vh;
  width: 100%;
  /* background-image: url("../assets/img/hbbg.png"); */
  padding-bottom: 100px;
}
.backjt {
  display: flex;
  width: 100%;
}
.jt {
  margin-top: 10px;
  margin-left: 10px;
  width: 30px;
  height: 30px;
}
.ewmmg {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 30px;
  background-image: url("../assets/img/hbbg.png");
  width: 250px;
  height: 250px;
}
.ewmcolor {
  color: azure;
}
.ewmwh {
  width: 191px;
  height: 191px;
}
.yqm {
  display: flex;
  justify-content: center;
  width: 100%;
}
.yqm-1 {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 65%;
  height: 53px;
  background-color: #fccf61;
  color: #0000ff;
  border-radius: 80px;
  font-size: 15px;
}
.jiangli {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 30px;
}
.jiangli-1 {
  display: flex;
  align-items: center;
  width: 60%;
}
.jiangli-2 {
  display: flex;
  justify-content: space-between;
}
.imgwh {
  width: 15px;
  height: 15px;
}
.yqcolor {
  display: flex;
  align-items: center;
  color: #551a8b;
  font-size: 12px;
}
.yqzh {
  width: 90%;
  display: flex;
  justify-content: space-around;
  background-color: #f6f6f6;
  height: 33px;
}
.backr {
  display: flex;
  align-items: center;
  color: #3476fe;
}
.guizhe {
  width: 100%;
  /* height: auto; */
  display: flex;
}
.mgleft {
  margin-left: 13%;
  margin-top: 4%;
  background-color: #ff9898;
  width: 120px;
  height: 25px;
  font-size: 12px;
  color: aliceblue;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 80px;
}
.hdgz {
  width: 75%;
  margin-top: 2%;
  display: flex;
  flex-direction: column;
  align-content: center;
  font-size: 14px;
  color: #f6f6f6;
}
</style>