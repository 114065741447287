<template>
  <div class="BOX">
    <div class="one-box3">
      <div>
        &nbsp;&nbsp;&nbsp;<img
          src="../assets/img/back.png"
          alt=""
          class="pic-back"
          @click="back"
        />
      </div>
      <div>{{ language.a }}</div>
      <div><img src="../assets/img/bjyd.png" class="bjyd" alt="" /></div>
    </div>
    <template v-if="state1">
      <div class="mesg" v-for="(item, index) in items" :key="{ index }">
        <div class="boxw">
          <div class="boxw-1">{{ language.b }}</div>
          <div>{{ item.title }}</div>
        </div>
        <div class="boxw">
          <div class="boxw-1">{{ language.c }}</div>
          <div>{{ item.ctime }}</div>
        </div>
        <div class="boxw">
          <div class="boxw-1">{{ language.d }}</div>
          {{ item.content }}
          <div></div>
        </div>
        <div class="boxw">
          <div class="boxw-1">{{ language.e }}</div>
          <div v-if="item.flag == '已读'" style="color: green">
            {{ item.flag }}
          </div>
          <div v-if="item.flag == '未读'" style="color: orange">
            {{ item.flag }}
          </div>
          <div class="bt-box">
            <button @click="bjyd(item.id)" class="btyd">
              {{ language.f }}
            </button>
          </div>
        </div>
      </div>
      </template>

    <div class="gd" v-if="state2">{{ language.g }}</div>
    <div>&nbsp;&nbsp;{{ mesaage }}</div>
    <Menu></Menu>
  </div>
</template>
<script>
import axios from "axios";
import Menu from "../components/Menu.vue"
export default {
  components:{
    Menu
  },
  mounted() {
    if (localStorage.getItem("language") == "chinese") {
      this.language = this.languageChinese;
    }
    if (localStorage.getItem("language") == "english") {
      this.language = this.languageEnglish;
    }
    if (localStorage.getItem("language") == "JT") {
      this.language = this.languageJT;
    }
    if (localStorage.getItem("language") == "HY") {
      this.language = this.languageHY;
    }
    if (localStorage.getItem("language") == "TY") {
      this.language = this.languageTY;
    }
    if (localStorage.getItem("language") == "YDY") {
      this.language = this.languageYDY;
    }
    if (localStorage.getItem("language") == "RY") {
      this.language = this.languageRY;
    }
    this.Refresh();
  },
  methods: {
    bjyd(id) {
      ////console.log(id);
      let token = id;
      axios.get(`/userletter/updateflag.do?id=${+token}`).then((res) => {
        ////console.log(res);
      });
      location.reload();
    },
    Refresh() {
      let token = sessionStorage.getItem("token");
      axios
        .get(`/userletter/querybyuser.do?uid=${+token}`)
        .then((res) => {
          // ////console.log(res)
          if (res.data.data != "" && res.data.code == 200) {
            this.state2 = false;
            this.state1 = true;
            this.items = res.data.data.reverse();
            this.items.forEach((el) => {
              if (el.flag == 2) {
                el.flag = "已读";
              }
              if (el.flag == 1) {
                el.flag = "未读";
              }
            });
          } else {
            this.state1 = false;
            this.state2 = true;
          }
        })
        .catch();
    },
    back() {
      this.$router.go(-1);
    },
    into() {
      this.$router.push("/RecipientAccount");
    },
  },

  data() {
    return {
      mesaage: "",
      state1: true,
      state2: true,
      items: {},
      // 翻译数组
      language: {
        a: "客户消息",
        b: "标题:",
        c: "时间",
        d: "消息内容:",
        e: "状态:",
        f: "标记已读",
        g: "没有更多消息",
      },
      languageChinese: {
        a: "客户消息",
        b: "标题:",
        c: "时间",
        d: "消息内容:",
        e: "状态:",
        f: "标记已读",
        g: "没有更多消息",
      },
      languageEnglish: {
        a: "Customer Messages",
        b: "Title:",
        c: "Time",
        d: "Message Content:",
        e: "Status:",
        f: "Mark as Read",
        g: "No More Messages",
      },
      //繁体中文数组
      languageJT: {
        a: "客戶訊息",
        b: "標題:",
        c: "時間",
        d: "訊息內容:",
        e: "狀態:",
        f: "標記已讀",
        g: "沒有更多訊息",
      },
      //泰语数组
      languageTY: {
        a: "ข้อความลูกค้า",
        b: "หัวข้อ:",
        c: "เวลา",
        d: "เนื้อหาข้อความ:",
        e: "สถานะ:",
        f: "ทำเครื่องหมายວ່າបានอ่าน",
        g: "ไม่มีข้อความเพิ่มເຕີມ",
      },

      //韩语数组
      languageHY: {
        a: "고객 메시지",
        b: "제목:",
        c: "시간",
        d: "메시지 내용:",
        e: "상태:",
        f: "읽음 표시",
        g: "더 이상 메시지 없음",
      },

      //日语数组
      languageRY: {
        a: "顧客メッセージ",
        b: "タイトル:",
        c: "時間",
        d: "メッセージ内容:",
        e: "状態:",
        f: "既読にマーク",
        g: "もうメッセージはありません",
      },
      languageYDY: {
        a: "ग्राहक संदेश",
        b: "शीर्षक:",
        c: "समय",
        d: "संदेश सामग्री:",
        e: "स्थिति:",
        f: "पढ़ा हुआ चिह्नित करें",
        g: "अधिक संदेश नहीं हैं",
      },
    };
  },
};
</script>

<style scoped>
.btyd {
  color: rgb(141, 144, 145);
  height: 30px;
  background-color: rgb(220, 217, 217);
  border-radius: 5px;
  border-color: rgb(196, 199, 201);
}
.bt-box {
  padding-left: 30px;
}
.bjyd {
  width: 20px;
  height: auto;
  padding-right: 10px;
}
.boxw-1 {
  width: 30%;
}
.boxw {
  display: flex;
  padding-top: 7px;
  padding-bottom: 7px;
  padding-left: 7.5px;
}
.mesg {
  width: 100%;
  
  border-top: 1px solid rgb(156, 152, 152);
  border-bottom: 1px solid rgb(156, 152, 152);
  font-size: 14px;
  color: rgb(156, 152, 152);
}
.BOX {
  display: flex;
  align-items: center;
  flex-direction: column;
  box-sizing: border-box;
  padding-bottom: 40px;
  min-height: 100vh;
  margin-top: 0px;
  background-color: rgb(217, 222, 222);
}
.one-box {
  display: flex;
  flex-direction: column;
  align-content: center;
  width: 100%;
  margin-top: 15px;

  background-color: rgb(217, 222, 222);
}
.one-box1 {
  display: flex;
  justify-content: space-between;
  height: 30px;
  color: rgb(144, 148, 148);
  margin-top: 10px;
  margin-left: 10px;
  margin-bottom: 10px;
}
.one-box2 {
  display: flex;
  width: 100%;
}
.one-box3 {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 4%;
  background-color: rgb(255, 255, 255);
  padding: 10px 0;
}

.pic-back {
  width: 10px;
  widows: 10px;
}
.box-2-2 {
  width: 100%;
}
.xhx {
  width: 100%;
  height: 1px;
  background-color: rgb(208, 203, 203);
}
.back2-pick {
  width: 20px;
  height: 20px;
}
.gd {
  margin-top: 30vh;
  color: rgb(149, 151, 153);
}
</style>