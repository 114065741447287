<template>
  <div class="BOX">
    <!-- onebox -->
    <div class="one-box">
      <div class="one-box1">
        <div class="svgy"></div>
        <div>{{language8.a8}}</div>
      </div>
      <div class="mg-rigt-close">
        <div class="close-wh" @click="goMy">
          <img src="../assets/img/close.png" alt="" class="pic-close" />
        </div>
      </div>
    </div>
    <!-- twobox -->
    <div></div>
    <div class="two-box">
      <div class="two-box-1">
        <div class="two-box-1-1"></div>
        <div class="two-box-1-2"></div>
      </div>
      <div class="two-box-2"><img src="../assets/img/xinxi.svg" alt="" /></div>
    </div>
    <!-- 3box -->
    <div class="three-box">
      <div>{{language8.b8}}</div>
      <div class="three-box-1">
        <div class="three-box-1-left">
          <div>&nbsp;&nbsp;&nbsp;{{language8.c8}}</div>
          <div>
            &nbsp;&nbsp;<input
              type="text"
              class="input-wh1"
              v-model="data.name"
              value=""
            />
          </div>

          <div class="three-box-1-left-wh">&nbsp;&nbsp;&nbsp;&nbsp;{{language8.d8}}</div>
          <div class="star">
            <div class="star-color">*</div>
            <div>
              <input type="text" class="input-wh2" v-model="data.email" />
            </div>
          </div>

          <div class="three-box-1-left-wh">&nbsp;&nbsp;{{language8.e8}}</div>
          <div class="star">
            <div class="star-color">*</div>
            <div>
              <input type="text" class="input-wh3" v-model="data.taty" />
            </div>
          </div>

          <div class="three-box-1-left-wh">&nbsp;&nbsp;{{language8.f8}}</div>
          <div>
            &nbsp;<input
              type="text"
              class="input-wh4"
              id="text4"
              v-model="data.info"
            />
          </div>
        </div>
      </div>
      <div class="four-bt-box">
        <button class="four-bt" @click="submitto">{{language8.g8}}</button>
      </div>
    </div>
    <Menu></Menu>
  </div>
</template>

<script>
import axios from "axios";
import Menu from "../components/Menu.vue";
export default {
  components:{
    Menu
  },
  data() {
    return {
      data: {
        email: "",
        info: "",
        name: "",
        taty: "",
      },
      // 翻译数组
      language8: {
        a8: "留言",
        b8: "抱歉，这个功能暂未开放上线。",
        c8: "您的姓名:",
        d8: "邮箱:",
        e8: "标题:",
        f8: "信息/消息:",
        g8: "提交",
      },
      languageChinese8: {
        a8: "留言",
        b8: "抱歉，这个功能暂未开放上线。",
        c8: "您的姓名:",
        d8: "邮箱:",
        e8: "标题:",
        f8: "信息/消息:",
        g8: "提交",
      },
      languageEnglish8: {
        a8: "Leave a Message",
        b8: "Sorry, this feature is not available yet.",
        c8: "Your Name:",
        d8: "Email:",
        e8: "Subject:",
        f8: "Message:",
        g8: "Submit",
      },
      //繁体中文数组
      languageJT8: {
        a8: "留言",
        b8: "抱歉，這個功能暫未開放上線。",
        c8: "您的姓名:",
        d8: "電子郵箱:",
        e8: "標題:",
        f8: "信息/訊息:",
        g8: "提交",
      },
      //泰语数组
      languageTY8: {
        a8: "ข้อความ",
        b8: "ขออภัย ฟังก์ชันนี้ยังไม่พร้อมเปิดใช้งาน",
        c8: "ชื่อ:",
        d8: "อีเมล:",
        e8: "หัวข้อ:",
        f8: "ข้อมูล/ข้อความ:",
        g8: "ส่ง",
      },

      //韩语数组
      languageHY8: {
        a8: "댓글",
        b8: "죄송합니다. 이 기능은 아직 서비스에 공개하지 않았습니다.",
        c8: "당신의 이름:",
        d8: "이메일:",
        e8: "제목:",
        f8: "정보/메시지:",
        g8: "제출",
      },

      //日语数组
      languageRY8: {
        a8: "メッセージ",
        b8: "申し訳ありません、この機能は現在まだ公開されていません。",
        c8: "お名前:",
        d8: "メールアドレス:",
        e8: "タイトル:",
        f8: "情報/メッセージ:",
        g8: "送信",
      },
      languageYDY8: {
        a8: "संदेश",
        b8: "माफ करना, यह फंक्शन अभी तक ऑनलाइन नहीं किया गया है。",
        c8: "आपका नाम:",
        d8: "ईमेल:",
        e8: "शीर्षक:",
        f8: "सूचना/संदेश:",
        g8: "जमा करें",
      },

      //印度语数组
    };
  },
  mounted() {
    if (localStorage.getItem("language") == "chinese") {
      this.language8 = this.languageChinese8;
    }
    if (localStorage.getItem("language") == "english") {
      this.language8 = this.languageEnglish8;
    }
    if (localStorage.getItem("language") == "JT") {
      this.language8 = this.languageJT8;
    }
    if (localStorage.getItem("language") == "HY") {
      this.language8 = this.languageHY8;
    }
    if (localStorage.getItem("language") == "TY") {
      this.language8 = this.languageTY8;
    }
    if (localStorage.getItem("language") == "YDY") {
      this.language8 = this.languageYDY8;
    }
    if (localStorage.getItem("language") == "RY") {
      this.language8 = this.languageRY8;
    }
  },
  methods: {
    goMy() {
      this.$router.push("/my");
    },
    submitto() {
      let token = sessionStorage.getItem("token");
      ////console.log(this.data);
      if (
        this.data.name == "" ||
        this.data.email == "" ||
        this.data.info == "" ||
        this.data.taty == ""
      ) {
        this.$message.error("please compelete information!");
        return;
      }
      axios.post(`/message/add.do`, this.data).then((res) => {
        ////console.log(res);
        if (res.data.code == 200) {
          this.$router.push("/my");
          this.$message.success("提交成功！");
          return;
        }
        if (res.data.code == 500) {
          this.$message.error("提交失败!");
          return;
        }
      });
    },
  },
};
</script>

<style scoped>
.BOX {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.close-wh {
  cursor: pointer;
}

.one-box {
  width: 100%;
  display: flex;
  justify-content: space-between;
  background-color: #298dd3;
  height: 40px;
}
.one-box1 {
  width: 100%;

  display: flex;
  align-items: center;
}
.svgy {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: rgb(46, 49, 51);
  display: flex;
  flex-direction: column;
  margin-left: 10px;
}
.pic-close {
  width: 20px;
  height: 20px;
}

.two-box {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.two-box-1 {
  width: 80%;
  height: 76.5px;
  display: flex;
  flex-direction: column;
}
.two-box-1-1 {
  width: 100%;
  height: 50%;
  background-color: #298dd3;
}
.three-box {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 15px;
}
.three-box-1 {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 20px;
}
.input-wh1 {
  width: 280px;
  height: 36px;
  border-radius: 10px;
  border-width: 0.1px;
  border-color: rgb(28, 190, 239);
  box-shadow: 0px;
}
.input-wh2 {
  width: 280px;
  height: 36px;
  border-radius: 10px;
  border-width: 0.1px;
  border-color: rgb(28, 190, 239);
  box-shadow: 0px;
}
.input-wh3 {
  width: 280px;
  height: 36px;
  border-radius: 10px;
  border-width: 0.1px;
  border-color: rgb(28, 190, 239);
  box-shadow: 0px;
}
.input-wh4 {
  width: 280px;
  height: 36px;
  border-radius: 10px;
  border-width: 0.1px;
  border-color: rgb(28, 190, 239);
  box-shadow: 0px;
}
.three-box-1-lef {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.star {
  display: flex;
}
.star-color {
  color: red;
}
.three-box-1-left-wh {
  margin-top: 35px;
}
.four-bt-box {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 50px;
}
.four-bt {
  width: 35%;
  height: 40px;
  border-radius: 10px;
  background-color: #69afe0;
  font-size: 25px;
  border: none;
  color: aliceblue;
}
.mg-rigt-close {
  margin-top: 7px;
  margin-right: 10px;
}
</style>