import Vue from "vue";
import VueRouter from "vue-router";
import CustomerService from "../views/CustomerService";
import WithdrawalRecords from "../views/WithdrawalRecords";
import RechargeRecords from "../views/RechargeRecords";
import PersonalData from "../views/PersonalData";
import RecipientAccount from "../views/RecipientAccount";
import PaymentMethod from "../views/PaymentMethod";
import SecuritySetting from "../views/SecuritySetting";
import Modifylogin from "../views/Modifylogin";
import ModifyPayment from "../views/ModifyPayment";
import UserMessages from "../views/UserMessages";
import PromotionandPosters from "../views/PromotionandPosters";
import LatestAnnouncement from "../views/LatestAnnouncement";
import LatestAnnouncement2 from "../views/LatestAnnouncement2";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "login",
    component: () => import("../views/Login.vue"),
  },
  {
    path: "/my",
    name: "my",
    component: () => import("../views/My.vue"),
  },
  {
    path: "/home",
    name: "home",
    component: () => import("../views/HomeView.vue"),
  },
  {
    path: "/registe",
    name: "registe",
    component: () => import("../views/Registe.vue"),
  },
  {
    path: "/tradeOrderView",
    name: "tradeOrderView",
    component: () => import("../views/TradeOrderView.vue"),
  },
  {
    path: "/Promotionrecords",
    name: "Promotionrecords",
    component: () => import("../views/Promotionrecords.vue"),
  },
  {
    path: "/wallet",
    name: "Wallet",
    component: () => import("../views/Wallet.vue"),
  },
  {
    name: "CustomerService",
    path: "/CustomerService",
    component: CustomerService,
  },
  {
    name: "WithdrawalRecords",
    path: "/WithdrawalRecords",
    component: WithdrawalRecords,
  },
  {
    name: "RechargeRecords",
    path: "/RechargeRecords",
    component: RechargeRecords,
  },
  {
    name: "PersonalData",
    path: "/PersonalData",
    component: PersonalData,
  },
  {
    name: "RecipientAccount",
    path: "/RecipientAccount",
    component: RecipientAccount,
  },
  {
    name: "PaymentMethod",
    path: "/PaymentMethod",
    component: PaymentMethod,
  },
  {
    name: "SecuritySetting",
    path: "/SecuritySetting",
    component: SecuritySetting,
  },
  {
    name: "Modifylogin",
    path: "/Modifylogin",
    component: Modifylogin,
  },
  {
    name: "ModifyPayment",
    path: "/ModifyPayment",
    component: ModifyPayment,
  },
  {
    name: " UserMessages",
    path: "/UserMessages",
    component: UserMessages,
  },
  {
    name: "PromotionandPosters",
    path: "/PromotionandPosters",
    component: PromotionandPosters,
  },
  {
    path: "/LatestAnnouncement",
    name: "LatestAnnouncement",
    component: LatestAnnouncement,
  },
  {
    path: "/LatestAnnouncement2",
    name: "LatestAnnouncement2",
    component: LatestAnnouncement2,
  },
  {
    path: "/fiscalmanagement",
    name: "Fiscalmanagement",
    component: () => import("../views/Fiscalmanagement.vue"),
  },
  {
    path: "/withdrawal",
    name: "withdrawal",
    component: () => import("../views/Withdrawal.vue"),
  },
  {
    path: "/HomeDetails",
    name: "HomeDetails",
    component: () => import("../views/HomeDetails.vue"),
  },
  {
    path: "/Bindbankcard",
    name: "Bindbankcard",
    component: () => import("../views/Bindbankcard.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    //x代表横坐标，y代表纵坐标
    return { x: 0, y: 0 };
  },
});

export default router;
